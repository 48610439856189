import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import Papa from 'papaparse';

import {
  CalendarEventIndex,
  CalendarEventIndexRaw,
  CalendarMatchupFull,
  EyeChartData,
  EyeChartRow,
  FullCalendarEventIndexRaw,
  FullScheduleMetricsEntry,
  FullScheduleMetricsEntryRaw,
  GameEntry,
  MlbFullSchedule,
  MlbSchedule,
  SCHEDULE_INDEX_MAP,
  TeamMetricsEntry,
  TeamType,
} from './scheduleConsts';

dayjs.extend(customParseFormat);
// @ts-ignore
window.dayjs = dayjs;

export const getScheduleJson: (
  id: string,
  team: TeamType
) => Promise<CalendarEventIndexRaw> = async (id: string, team: TeamType) => {
  try {
    const { default: data }: { default: CalendarEventIndexRaw } = await import(
      `../data/schedules/${id}/json/${team}_schedule.json`
    );
    return data;
  } catch (e) {
    // No schedule found for this team.
    return {};
  }
};

export const getFullScheduleJson: (id: string) => Promise<FullCalendarEventIndexRaw> = async (
  id: string
) => {
  const { default: data }: { default: FullCalendarEventIndexRaw } = await import(
    `../data/schedules/${id}/json/full_schedule.json`
  );
  return data;
};

export const getFullSchedule: (id: string) => Promise<CalendarMatchupFull[]> = async (
  id: string
) => {
  const raw = await getFullScheduleJson(id);
  const entries: CalendarMatchupFull[] = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const [date, matchups] of Object.entries(raw)) {
    // eslint-disable-next-line no-restricted-syntax
    for (const matchup of matchups) {
      entries.push({
        date,
        ...matchup,
      });
    }
  }
  return entries;
};

export const getTeamScheduleMetrics: (
  id: string,
  teamName: TeamType
) => Promise<TeamMetricsEntry> = async (id: string, teamName: TeamType) => {
  try {
    const { default: data }: { default: TeamMetricsEntry } = await import(
      `../data/schedules/${id}/json/${teamName}_schedule_metrics.json`
    );
    return data;
  } catch (e) {
    // No schedule metrics found for this team.
    return {
      viewership_total: 0,
      travel_distance_in_miles_total: 0,
      longest_home_stand_length: 0,
      longest_road_trip_length: 0,
      back_to_back_match_count: 0,
      three_matches_in_five_days_count: 0,
      border_crossings_count: 0,
    };
  }
};

export const getFullScheduleMetrics: (id: string) => Promise<FullScheduleMetricsEntry> = async (
  id: string
) => {
  try {
    const { default: data }: { default: FullScheduleMetricsEntryRaw } = await import(
      `../data/schedules/${id}/json/full_schedule_metrics.json`
    );
    return {
      id,
      // @ts-ignore
      name: SCHEDULE_INDEX_MAP[id] || 'Unknown',
      ...data,
    };
  } catch (e) {
    // No schedule metrics found for this team.
    return {
      id: '-1',
      name: 'N/A',
      viewership_total: 0,
      travel_distance_in_miles_average: 0,
      travel_distance_in_miles_total: 0,
      longest_home_stands_length: 0,
      longest_road_trip_length: 0,
      back_to_back_match_count: 0,
      three_matches_in_five_days_count: 0,
      us_team_border_crossings_border_crossings_count: 0,
      canadian_team_border_crossings_border_crossings_count: 0,
    };
  }
};

export const getImportedIframeUrl: (targetUrl: string) => Promise<string> = async (
  targetUrl: string
) => {
  const resUrl = new URL(`../data/${targetUrl}`, import.meta.url).href;
  return resUrl;
};

export const processCalendarIndexRaw: (
  rawIndex: CalendarEventIndexRaw,
  selectedTeam: TeamType
) => CalendarEventIndex = (rawIndex: CalendarEventIndexRaw, selectedTeam: TeamType) => {
  const index: CalendarEventIndex = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const [date, matchup] of Object.entries(rawIndex)) {
    if (matchup.home === selectedTeam) {
      index.push({
        title: `${matchup.away}`,
        date,
        eastern_time: matchup.eastern_time,
        className: ['event', 'home-event'],
        textColor: 'black',
      });
    } else {
      index.push({
        title: `@${matchup.home}`,
        date,
        eastern_time: matchup.eastern_time,
        className: ['event', 'away-event'],
        textColor: 'black',
      });
    }
  }
  return index;
};

export const getMlbFullSchedule: (id: string) => Promise<MlbFullSchedule> = async (id: string) => {
  const schedule = await import(`../data/schedules/${id}/json/full_schedule.json`);
  return schedule;
};

export const processMlbEntriesForCalendar: (
  currentScheduleId: string,
  selectedTeam: TeamType
) => Promise<CalendarEventIndex> = async (currentScheduleId: string, selectedTeam: TeamType) => {
  const schedule = await import(`../data/schedules/${currentScheduleId}/json/full_schedule.json`);
  const mlbSchedule = schedule.schedule;
  const index: CalendarEventIndex = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const matchup of mlbSchedule) {
    if (![matchup.home, matchup.away].includes(selectedTeam)) {
      // eslint-disable-next-line no-continue
      continue;
    }
    if (matchup.home === selectedTeam) {
      index.push({
        title: `${matchup.away}`,
        date: matchup.date,
        eastern_time: matchup.time || '',
        className: ['event', 'home-event'],
        textColor: 'black',
      });
    } else {
      index.push({
        title: `@${matchup.home}`,
        date: matchup.date,
        eastern_time: matchup.time || '',
        className: ['event', 'away-event'],
        textColor: 'black',
      });
    }
  }
  return index;
};

export const parseCSV = (data: string): void => {
  Papa.parse(data, {
    header: true,
    skipEmptyLines: true,
    complete: (results) => {
      const jsonEntries: GameEntry[] = [];

      results.data.forEach((row: any) => {
        const week = parseInt(row.week, 10);

        Object.keys(row).forEach((key) => {
          if (key !== 'week' && key !== 'date') {
            const awayTime = row[key];
            if (awayTime) {
              const [awayTeam, time] = awayTime.split('\n', 2);
              jsonEntries.push({
                week,
                date: row.date,
                home: key,
                away: awayTeam.trim(),
                time: time ? time.trim() : undefined,
              });
            }
          }
        });
      });

      // Output the JSON data
      // console.log(JSON.stringify(jsonEntries, null, 2));
      console.log(jsonEntries);
    },
  });
};
// @ts-ignore
window.parseCSVToJSON = parseCSV;

export const getEyeChartData = (schedule: MlbSchedule): EyeChartData => {
  let dates = schedule.map((game) => game.date);
  const retVal: EyeChartData = [];
  dates = [...new Set(dates)].sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

  let prevDate = dates[0];
  // eslint-disable-next-line no-restricted-syntax
  for (const date of dates) {
    if (dayjs(date).diff(dayjs(prevDate), 'day') > 1) {
      retVal.push({
        date: dayjs(prevDate).add(1, 'day').format('YYYY-MM-DD'),
        isBreak: true,
      } as EyeChartRow);
    }
    retVal.push({ date } as EyeChartRow);
    prevDate = date;
  }

  schedule.forEach((game) => {
    const row = retVal.find((entry) => entry.date === game.date);
    if (!row) {
      throw new Error('Row not found');
    }
    row[game.home] = game;
    row[game.away] = game;
  });

  return retVal;
};

export const formatNumber = (num: number) => {
  let fractionDigits = num >= 1000000 ? 2 : 1;
  if (num === Math.floor(num) && num < 1000) {
    fractionDigits = 0;
  }
  return new Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(num);
};
