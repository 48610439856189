import { Stack, Group, Select, Text, Image, Title, Box, SimpleGrid, Table } from '@mantine/core';
import { getRouteApi, useNavigate } from '@tanstack/react-router';
import { useState, useEffect } from 'react';
import { useAppStore } from 'stores/appStore';
import { MLB_TEAM_KEYS, MLB_TEAM_NAME_MAP, MlbTeamType } from 'utils/scheduleConsts';
import {
  MLB_2024_TEAM_METRICS_INDEX,
  MLB_2025_TEAM_METRICS_INDEX,
  MlbTeamMetricEntry,
  TeamMatchCounts,
} from 'utils/scheduleData';

import { formatNumber, getScheduleJson } from 'utils/scheduleUtils';
import classes from './Teamreport.module.css';

function TeamMetricsGrid({ metrics }: { metrics: MlbTeamMetricEntry }) {
  return (
    <Box className={classes.metricsGridRoot}>
      <Box className={classes.metricsGridColumn}>
        <Box className={classes.metricsGridItem}>
          <Box className={classes.metricsGridItemLabel} w={115}>
            Total Games
          </Box>
          <Box className={classes.metricsGridItemValue}>{metrics.total_matches}</Box>
        </Box>
        <Box className={classes.metricsGridItem}>
          <Box className={classes.metricsGridItemLabel} w={115}>
            Travel Distance
          </Box>
          <Box className={classes.metricsGridItemValue}>
            {formatNumber(metrics.travel_distance_miles_total)} mi
          </Box>
        </Box>
      </Box>
      <Box className={classes.metricsGridColumn}>
        <Box className={classes.metricsGridItem}>
          <Box className={classes.metricsGridItemLabel} w={105}>
            Home Games
          </Box>
          <Box className={classes.metricsGridItemValue}>{metrics.home_matches}</Box>
        </Box>
        <Box className={classes.metricsGridItem}>
          <Box className={classes.metricsGridItemLabel} w={105}>
            Travel Time
          </Box>
          <Box className={classes.metricsGridItemValue}>
            {formatNumber(metrics.travel_time_in_minutes_total / 1440)} days
          </Box>
        </Box>
      </Box>
      <Box className={classes.metricsGridColumn}>
        <Box className={classes.metricsGridItem}>
          <Box className={classes.metricsGridItemLabel} w={140}>
            Road Games
          </Box>
          <Box className={classes.metricsGridItemValue}>{metrics.away_match}</Box>
        </Box>
        <Box className={classes.metricsGridItem}>
          <Box className={classes.metricsGridItemLabel} w={140}>
            20 Game Stretches
          </Box>
          <Box className={classes.metricsGridItemValue}>{metrics.twenty_games_streak_count}</Box>
        </Box>
      </Box>
    </Box>
  );
}

const MatchCountTable = ({
  data,
  title,
}: {
  data: Record<MlbTeamType, TeamMatchCounts>;
  title: React.ReactNode;
}) => (
  <Stack align="center" gap="xs">
    {title}
    <Table striped highlightOnHover withTableBorder>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Club</Table.Th>
          <Table.Th>Home Games</Table.Th>
          <Table.Th>Road Games</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {Object.entries(data).map(([team, counts]) => (
          <Table.Tr key={team}>
            <Table.Td>
              <Group>
                <Image
                  src={`https://s3.us-east-1.amazonaws.com/recentive-static/assets/img/mlb_logos/${team}_logo.png`}
                  height={24}
                  width="auto"
                  fit="cover"
                  alt={`${team} logo`}
                />
                <Text>{team}</Text>
              </Group>
            </Table.Td>
            <Table.Td>{counts.home}</Table.Td>
            <Table.Td>{counts.away}</Table.Td>
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table>
  </Stack>
);

export function TeamReport() {
  const navigate = useNavigate({ from: '/schedules' });
  const currentScheduleId = useAppStore((state) => state.currentScheduleId);

  const routeSearch = getRouteApi('/_authenticated/schedules').useSearch();
  const [selectedTeam, setSelectedTeam] = useState<MlbTeamType>(
    routeSearch.team ? routeSearch.team : 'NYY'
  );

  const [data, setData] = useState<MlbTeamMetricEntry>();

  useEffect(() => {
    navigate({
      to: '/schedules',
      search: (prev) => ({ s: prev.s, t: 'team', team: selectedTeam, compare: prev.compare }),
    });

    async function fetchScheduleJson() {
      const teamSchedule = await getScheduleJson(currentScheduleId, selectedTeam);
      // @ts-ignore
      setData(teamSchedule.metrics);
    }

    if (currentScheduleId === '2') {
      setData(MLB_2024_TEAM_METRICS_INDEX[selectedTeam]);
    } else if (currentScheduleId === '1') {
      setData(MLB_2025_TEAM_METRICS_INDEX[selectedTeam]);
    } else {
      fetchScheduleJson();
    }
  }, [selectedTeam, currentScheduleId]);

  return (
    <Stack gap="xs" align="stretch" h="100%">
      <Group justify="">
        <Group align="center" mr="md">
          <Text fw={500}>Team</Text>
          <Select
            value={selectedTeam}
            onChange={(value) => setSelectedTeam(value as MlbTeamType)}
            allowDeselect={false}
            data={MLB_TEAM_KEYS.slice().sort()}
            searchable
            w="100px"
          />
          <Group gap="xs">
            <Box h={32}>
              <Image
                src={`https://s3.us-east-1.amazonaws.com/recentive-static/assets/img/mlb_logos/${selectedTeam}_logo.png`}
                height={32}
                width="auto"
                fit="cover"
                alt={`${selectedTeam} logo`}
              />
            </Box>
            <Title order={4}>
              {MLB_TEAM_NAME_MAP[selectedTeam as keyof typeof MLB_TEAM_NAME_MAP]}
            </Title>
          </Group>
        </Group>
        {data && <TeamMetricsGrid metrics={data} />}
      </Group>
      {data && (
        <Stack mt="lg" gap="xl">
          <SimpleGrid cols={3}>
            <MatchCountTable
              data={data.opponent_match_counts_by_division['American League East']}
              title={
                <Title order={5} c="#ff4252">
                  AL East
                </Title>
              }
            />
            <MatchCountTable
              data={data.opponent_match_counts_by_division['American League Central']}
              title={
                <Title order={5} c="#ff4252">
                  AL Central
                </Title>
              }
            />
            <MatchCountTable
              data={data.opponent_match_counts_by_division['American League West']}
              title={
                <Title order={5} c="#ff4252">
                  AL West
                </Title>
              }
            />
          </SimpleGrid>
          <SimpleGrid cols={3}>
            <MatchCountTable
              data={data.opponent_match_counts_by_division['National League East']}
              title={
                <Title order={5} c="#2498cc">
                  NL East
                </Title>
              }
            />
            <MatchCountTable
              data={data.opponent_match_counts_by_division['National League Central']}
              title={
                <Title order={5} c="#2498cc">
                  NL Central
                </Title>
              }
            />
            <MatchCountTable
              data={data.opponent_match_counts_by_division['National League West']}
              title={
                <Title order={5} c="#2498cc">
                  NL West
                </Title>
              }
            />
          </SimpleGrid>
        </Stack>
      )}
    </Stack>
  );
}
