// DO NOT REMOVE OR REORDER SCHEDULES 1 and 2
export const SCHEDULE_INDEX_MAP = {
  1: 'MLB Schedule 2025 (Official)',
  2: 'MLB Schedule 2024 (Official)',
  3: 'MLB Schedule 2026 - No True Off Days',
};

export const SCHEDULE_TO_YEAR_MAP = {
  1: 2025,
  2: 2024,
  3: 2026,
};

export type ScheduleListEntry = {
  id: string;
  name: string;
  created_at: string;
  job_id: string;
  is_valid: boolean;
};

export type JobListEntry = {
  id: string;
  status: string;
  created_at: string;
};

export type BuildListEntry = {
  id: string;
  user: string;
  jobs: JobListEntry[];
  results: ScheduleListEntry[];
  created_at: string;
  name: string;
};

export const TEAM_KEYS = [
  'ANA',
  'ARI',
  'BOS',
  'BUF',
  'CAR',
  'CBJ',
  'CGY',
  'CHI',
  'COL',
  'DAL',
  'DET',
  'EDM',
  'FLA',
  'LAK',
  'MIN',
  'MTL',
  'NJD',
  'NSH',
  'NYI',
  'NYR',
  'OTT',
  'PHI',
  'PIT',
  'SEA',
  'SJS',
  'STL',
  'TBL',
  'TOR',
  'UTA',
  'VAN',
  'VGK',
  'WPG',
  'WSH',
];

export const TEAM_NAME_MAP = {
  ANA: 'Anaheim Ducks',
  ARI: 'Arizona Coyotes',
  BOS: 'Boston Bruins',
  BUF: 'Buffalo Sabres',
  CAR: 'Carolina Hurricanes',
  CBJ: 'Columbus Blue Jackets',
  CGY: 'Calgary Flames',
  CHI: 'Chicago Blackhawks',
  COL: 'Colorado Avalanche',
  DAL: 'Dallas Stars',
  DET: 'Detroit Red Wings',
  EDM: 'Edmonton Oilers',
  FLA: 'Florida Panthers',
  LAK: 'Los Angeles Kings',
  MIN: 'Minnesota Wild',
  MTL: 'Montreal Canadiens',
  NJD: 'New Jersey Devils',
  NSH: 'Nashville Predators',
  NYI: 'New York Islanders',
  NYR: 'New York Rangers',
  OTT: 'Ottawa Senators',
  PHI: 'Philadelphia Flyers',
  PIT: 'Pittsburgh Penguins',
  SEA: 'Seattle Kraken',
  SJS: 'San Jose Sharks',
  STL: 'St. Louis Blues',
  TBL: 'Tampa Bay Lightning',
  TOR: 'Toronto Maple Leafs',
  UTA: 'Utah Hockey Club',
  VAN: 'Vancouver Canucks',
  VGK: 'Vegas Golden Knights',
  WPG: 'Winnipeg Jets',
  WSH: 'Washington Capitals',
};

export type TeamType = (typeof TEAM_KEYS)[number];

export type CalendarMatchupRaw = {
  home: TeamType;
  away: TeamType;
  eastern_time: string;
  viewership?: number;
  network?: string;
};

export type CalendarEventIndexRaw = {
  [key: string]: CalendarMatchupRaw;
};

export type FullCalendarEventIndexRaw = {
  [key: string]: CalendarMatchupRaw[];
};

export type CalendarMatchupFull = CalendarMatchupRaw & {
  date: string;
};

export type CalendarEvent = {
  title: string;
  date: string;
  eastern_time: string;
  network?: string;
  viewership?: number;
  className?: string[];
  textColor?: string;
};

export type CalendarEventIndex = CalendarEvent[];

export type TeamMetricsEntry = {
  viewership_total: number;
  travel_distance_in_miles_total: number;
  longest_home_stand_length: number;
  longest_road_trip_length: number;
  back_to_back_match_count: number;
  three_matches_in_five_days_count: number;
  five_matches_in_seven_days_count?: number;
  border_crossings_count?: number;
};

export type FullScheduleMetricsEntryRaw = {
  viewership_total: number;
  travel_distance_in_miles_average: number;
  travel_distance_in_miles_total: number;
  longest_home_stands_length: number;
  longest_road_trip_length: number;
  back_to_back_match_count: number;
  three_matches_in_five_days_count: number;
  five_matches_in_seven_days_count?: number;
  us_team_border_crossings_border_crossings_count?: number;
  canadian_team_border_crossings_border_crossings_count?: number;
  division_series_first_half?: number;
};

export type FullScheduleMetricsEntry = FullScheduleMetricsEntryRaw & {
  id: string;
  name: string;
};

export type UserInfoResponse = {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
};

/// MLB Consts ///

export const MLB_TEAM_KEYS = [
  // AL West
  'HOU',
  'LAA',
  'OAK',
  'SEA',
  'TEX',
  // AL Central
  'CLE',
  'CWS',
  'DET',
  'KC',
  'MIN',
  // AL East
  'BAL',
  'BOS',
  'NYY',
  'TB',
  'TOR',
  // NL West
  'AZ',
  'COL',
  'LAD',
  'SD',
  'SF',
  // NL Central
  'CHC',
  'CIN',
  'MIL',
  'PIT',
  'STL',
  // NL East
  'ATL',
  'MIA',
  'NYM',
  'PHI',
  'WSH',
];

export type MlbTeamType = (typeof MLB_TEAM_KEYS)[number];

export type GameEntry = {
  date: string;
  week?: number;
  home: MlbTeamType;
  away: MlbTeamType;
  time?: string;
};

export type MlbSchedule = GameEntry[];

// Final type used for the schedule grid.
export type EyeChartRow = {
  date: string;
  isBreak?: boolean;
} & {
  [K in TeamType]?: GameEntry;
};

export type EyeChartData = EyeChartRow[];

export const MLB_TEAM_NAME_MAP: Record<MlbTeamType, string> = {
  HOU: 'Houston Astros',
  LAA: 'Los Angeles Angels',
  OAK: 'Oakland Athletics',
  SEA: 'Seattle Mariners',
  TEX: 'Texas Rangers',
  CLE: 'Cleveland Guardians',
  CWS: 'Chicago White Sox',
  DET: 'Detroit Tigers',
  KC: 'Kansas City Royals',
  MIN: 'Minnesota Twins',
  BAL: 'Baltimore Orioles',
  BOS: 'Boston Red Sox',
  NYY: 'New York Yankees',
  TB: 'Tampa Bay Rays',
  TOR: 'Toronto Blue Jays',
  AZ: 'Arizona Diamondbacks',
  COL: 'Colorado Rockies',
  LAD: 'Los Angeles Dodgers',
  SD: 'San Diego Padres',
  SF: 'San Francisco Giants',
  CHC: 'Chicago Cubs',
  CIN: 'Cincinnati Reds',
  MIL: 'Milwaukee Brewers',
  PIT: 'Pittsburgh Pirates',
  STL: 'St. Louis Cardinals',
  ATL: 'Atlanta Braves',
  MIA: 'Miami Marlins',
  NYM: 'New York Mets',
  PHI: 'Philadelphia Phillies',
  WSH: 'Washington Nationals',
};

export const WEEK_KEYS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18] as const;

export const WEEK_KEYS_EXTENDED = ['All', ...WEEK_KEYS] as const;

export const WEEK_KEYS_BYE = [5, 6, 7, 8, 9, 10, 11, 12, 13, 14] as const;

export type WeekType = (typeof WEEK_KEYS)[number];

export const NETWORK_KEYS = [
  'Amz',
  'ESPN',
  'NFLN',
  'NBC',
  'Peacock',
  'CBS Single',
  'CBS Regional',
  'CBS DH',
  'Fox Single',
  'Fox Regional',
  'Fox DH',
  'Netflix',
] as const;

export const NETWORK_KEYS_SIMPLE = [
  'Amz',
  'ESPN',
  'NFLN',
  'NBC',
  'Peacock',
  'CBS',
  'Fox',
  'Netflix',
] as const;
export type NetworkTypeSimple = (typeof NETWORK_KEYS_SIMPLE)[number];

export const NETWORK_KEYS_SIMPLE_EXTENDED = ['All', ...NETWORK_KEYS_SIMPLE] as const;

export type NetworkTypeSimpleExtended = (typeof NETWORK_KEYS_SIMPLE_EXTENDED)[number];

export const NETWORK_KEYS_PRIMETIME = ['All', 'Amz', 'ESPN', 'NBC', 'NFLN'];

export type NetworkKeysPrimetimeType = (typeof NETWORK_KEYS_PRIMETIME)[number];

export type PercentageThresholdsIndex = {
  [networkKey in NetworkType]?: number;
};

export type NetworkType = (typeof NETWORK_KEYS)[number];

export type ViewershipPredictionsAverageEntry = {
  network: NetworkType;
  2023: number;
  2024: number;
};

export type ViewershipPredictionsDataRaw = {
  [networkKey in NetworkType]: {
    avg: {
      2023: number;
      2024: number;
    };
    weeks: {
      [weekKey in WeekType as string]: {
        [matchup: string]: { viewers: number; highlight: boolean };
      };
    };
  };
};

export type ViewershipPredictionsRowEntry = {
  week: WeekType;
} & {
  [networkKey in NetworkType]: {
    matchup: string;
    viewers: number;
    highlight: boolean;
  }[];
};

export type ViewershipPredictionsIndex = {
  viewership: ViewershipPredictionsRowEntry[];
  averages: ViewershipPredictionsAverageEntry[];
};

export enum ConstraintType {
  STADIUM_BLOCK = 'stadium_block',
  MATCHUP_GUARANTEE = 'matchup_guarantee',
  TEAM_GUARANTEE = 'team_guarantee',
  BYE_GUARANTEE = 'bye_guarantee',
  HOME_AWAY_GUARANTEE = 'home_away_guarantee',
  PRIMETIME_MIN_MAX = 'primetime_min_max',
}

export const ConstraintTypeTranslations: {
  [K in ConstraintType]: string;
} = {
  [ConstraintType.STADIUM_BLOCK]: 'Stadium Block',
  [ConstraintType.MATCHUP_GUARANTEE]: 'Matchup Guarantee',
  [ConstraintType.TEAM_GUARANTEE]: 'Team Guarantee',
  [ConstraintType.BYE_GUARANTEE]: 'Bye Guarantee',
  [ConstraintType.HOME_AWAY_GUARANTEE]: 'Home/Away Guarantee',
  [ConstraintType.PRIMETIME_MIN_MAX]: 'Primetime Min/Max',
};

export const DAYS_IN_ORDER = ['Wed', 'Thu', 'Fri', 'Sat', 'Sun', 'Mon'];

export enum ConstraintDay {
  WEDNESDAY = 'Wed',
  THURSDAY = 'Thu',
  FRIDAY = 'Fri',
  SATURDAY = 'Sat',
  SUNDAY = 'Sun',
  MONDAY = 'Mon',
}

export const ConstraintDayTranslations: {
  [K in ConstraintDay]: string;
} = {
  [ConstraintDay.WEDNESDAY]: 'Wednesday',
  [ConstraintDay.THURSDAY]: 'Thursday',
  [ConstraintDay.FRIDAY]: 'Friday',
  [ConstraintDay.SATURDAY]: 'Saturday',
  [ConstraintDay.SUNDAY]: 'Sunday',
  [ConstraintDay.MONDAY]: 'Monday',
};

export type ConstraintEntry =
  | StadiumBlock
  | MatchupGuarantee
  | TeamGuarantee
  | ByeGuarantee
  | HomeAwayGuarantee
  | PrimetimeMinMax;

export enum ConstraintSlot {
  ALL = 'all',
  TNF = 'tnf',
  SATURDAY = 'saturday',
  SUN_1 = 'sun_1',
  SUN_4 = 'sun_4',
  SNF = 'snf',
  MNF = 'mnf',
  CHRISTMAS = 'christmas',
  TGIV = 'tgiv',
  BLACK_FRIDAY = 'black_friday',
}

export type StadiumBlock = {
  type: ConstraintType.STADIUM_BLOCK;
  params: {
    team: TeamType;
    week: WeekType;
    slot: ConstraintSlot[];
  };
};

export enum HolidaySlot {
  CHRISTMAS = 'christmas',
  TGIV = 'tgiv',
  BLACK_FRIDAY = 'black_friday',
}

export const HolidaySlotTranslations: {
  [K in HolidaySlot]: string;
} = {
  [HolidaySlot.CHRISTMAS]: 'Christmas',
  [HolidaySlot.TGIV]: 'Thanksgiving',
  [HolidaySlot.BLACK_FRIDAY]: 'Black Friday',
};

export type WeekExtendedType = (typeof WEEK_KEYS_EXTENDED)[number];

export type MatchupGuarantee = {
  type: ConstraintType.MATCHUP_GUARANTEE;
  params: {
    matchup: string;
    week: WeekExtendedType[];
    network: NetworkTypeSimple[];
    inclusive: boolean;
    holiday_slot?: HolidaySlot;
  };
};

export type TeamGuarantee = {
  type: ConstraintType.TEAM_GUARANTEE;
  params: {
    team: TeamType[];
    week: WeekExtendedType[];
    network: NetworkTypeSimple[];
    min_appearances: number;
    max_appearances: number;
    holiday_slot?: HolidaySlot;
  };
};

export type WeekByeType = (typeof WEEK_KEYS_BYE)[number];

export type ByeGuarantee = {
  type: ConstraintType.BYE_GUARANTEE;
  params: {
    team: TeamType;
    week: WeekByeType[];
    inclusive: boolean;
  };
};

export type HomeAwayGuarantee = {
  type: ConstraintType.HOME_AWAY_GUARANTEE;
  params: {
    team: TeamType[];
    home_away: 'home' | 'away';
    week: WeekExtendedType[];
    network: NetworkTypeSimpleExtended[];
    holiday_slot?: HolidaySlot;
    min_appearances: number;
    max_appearances: number;
  };
};

export type PrimetimeMinMax = {
  type: ConstraintType.PRIMETIME_MIN_MAX;
  params: {
    team: TeamType[];
    network: NetworkKeysPrimetimeType[];
    min_appearances: number;
    max_appearances: number;
  };
};

export type StaticConstraintEntry = {
  description: string;
  summary: string;
};

export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

export const ConstraintSlotTranslations: {
  [K in ConstraintSlot]: string;
} = {
  [ConstraintSlot.ALL]: 'All',
  [ConstraintSlot.TNF]: 'TNF',
  [ConstraintSlot.SATURDAY]: 'Saturday',
  [ConstraintSlot.SUN_1]: 'Sunday 1pm',
  [ConstraintSlot.SUN_4]: 'Sunday 4pm',
  [ConstraintSlot.SNF]: 'SNF',
  [ConstraintSlot.MNF]: 'MNF',
  [ConstraintSlot.CHRISTMAS]: 'Christmas',
  [ConstraintSlot.TGIV]: 'Thanksgiving',
  [ConstraintSlot.BLACK_FRIDAY]: 'Black Friday',
};

export type MlbFullSchedule = {
  schedule: MlbSchedule;
  metrics: FullScheduleMetricsEntryRaw;
};
